import React from 'react';
import { PageLayout } from "./components/UI/PageLayout";
import { MsalProvider } from '@azure/msal-react';
import { Home } from "./components/Pages/Home";
import { Route, Routes } from 'react-router-dom';
import { Projects } from "./components/Pages/Projects";
import { Admin } from "./components/Pages/Admin";

const Pages = () => {
    return (
        <Routes>
            <Route path="/" element={<PageLayout />}>
                <Route index element={<Home />} />
                <Route path="projects/*" element={<Projects />} />
                <Route path="admin" element={<Admin />} />                
            </Route>
        </Routes>
    );
}

const App = ({ instance }) => {
    return (
        <MsalProvider instance={instance}>
            <Pages />
        </MsalProvider>
    );
};

export default App;
