import { useMsal, AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { loginApiRequest } from "../../authconfig";
import Button from 'react-bootstrap/Button';

export const LoginButton = () => {
    const { instance } = useMsal();

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    const handleLogin = () => {
        instance.loginRedirect({
            loginApiRequest
        });
    };

    return (
        <>
            <UnauthenticatedTemplate>
                <Button variant="outline-primary" size="sm" onClick={handleLogin}>Sign In</Button>
            </UnauthenticatedTemplate>
            <AuthenticatedTemplate>
                <Button variant="outline-primary" size="sm" onClick={handleLogout}>Sign Out</Button>
            </AuthenticatedTemplate>
        </>
    );
};