import { React } from 'react'
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { useMsal } from "@azure/msal-react";
import { Container, Row, Col, Card } from "react-bootstrap";
import { LoginButton } from '../UI/LoginButton'
import { Navigate } from "react-router-dom";

export const Home = () => {
    const { instance } = useMsal();
    const activeAccount = instance.getAllAccounts()[0];

    return (
        <>
            <AuthenticatedTemplate>
                {activeAccount ?
                    <Container>
                        <Navigate to="projects/" replace />;
                    </Container> : null}
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Container>
                    <Container>
                        <Row>
                            <Col sm={{ span: 6, offset: 3 }}>
                                <Card>
                                    <Card.Body>
                                        <Card.Title>Sign In</Card.Title>
                                        <Card.Text>
                                            Please Sign In To Continue
                                        </Card.Text>
                                        <div className="d-grid gap-2 col-6 mx-auto">
                                            <LoginButton />
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        </Row>
                    </Container>
                </Container>
            </UnauthenticatedTemplate>
        </>
    );
};