import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Link } from 'react-router-dom'
import { AuthenticatedTemplate, UnauthenticatedTemplate, useMsal } from '@azure/msal-react';

export const NavigationBar = (props) => {
    const { instance } = useMsal();
    const activeAccount = instance.getAllAccounts()[0];

    const handleLogout = () => {
        instance.logoutRedirect({
            postLogoutRedirectUri: "/",
        });
    };

    const NameClicked = () => {
        handleLogout();
    };


    return (
        <Navbar style={{ backgroundColor: "#007396"}} variant="dark" className="mb-5">
            <Container>
                <Navbar.Brand as={Link} to="/">Universal Design</Navbar.Brand>
                <Navbar.Toggle />
                <AuthenticatedTemplate>
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link as={Link} to="/projects">Projects</Nav.Link>
                            <Nav.Link as={Link} to="/admin">Admin</Nav.Link>
                        </Nav>
                        <Nav className="justify-content-end" onClick={() => NameClicked()} style={{ cursor: "pointer" }}>
                            {activeAccount != null ? <Navbar.Text>Hello {activeAccount.name}!</Navbar.Text> : <p></p>}
                        </Nav>
                    </Navbar.Collapse>
                </AuthenticatedTemplate>
                <UnauthenticatedTemplate>
                    <Nav className="me-auto">
                        <Nav.Link as={Link} to="/">Home</Nav.Link>
                    </Nav>
                </UnauthenticatedTemplate>
            </Container>
        </Navbar>
    );
};

