import { React, useState } from 'react'
import { Container, Form, Button, Modal } from "react-bootstrap";

const AddProjectModal = (props) => {

    const handleClose = () => props.setShowAddProjectModal(false);
    const [projectName, setProjectName] = useState(false);
    const [clientName, setClientName] = useState(false);

    const handleCreateProject = () => {
        const projectToAdd = {
            Name: projectName,
            ClientName: clientName
        };
        props.setShowAddProjectModal(false);
        props.setSaveProject(true);
        props.setProjectToAdd(projectToAdd);
        props.setIsLoading(true);
    };

    return (
        <>
            <Modal show={props.showAddProjectModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>Add a project</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="row g-3">
                        <Form.Group>
                            <Form.Label>Project Name</Form.Label>
                            <Form.Control onChange={(e) => setProjectName(e.target.value)} type="text"></Form.Control>
                        </Form.Group>
                        <Form.Group>
                            <Form.Label>Client Name</Form.Label>
                            <Form.Control onChange={(e) => setClientName(e.target.value)} type="text"></Form.Control>
                        </Form.Group>
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}>Close</Button>
                    <Button variant="outline-primary" onClick={handleCreateProject}>Create Project</Button>
                </Modal.Footer>
            </Modal>
            <Container>
                
            </Container>
        </>
        )
};

export default AddProjectModal;