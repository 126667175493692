import { Accordion, useAccordionButton, Button, Card, OverlayTrigger, Tooltip } from "react-bootstrap";
import { React, useState } from 'react';
import { ApiManager } from '../../ApiManager';
import Chapters from './Chapters';
import ChapterModal from '../Modals/ChapterModal';
import SectionModal from '../Modals/SectionModal';
import ConfirmationModal from '../Modals/ConfirmationModal';
import PencilIcon from '../../Assets/Images/pencil-square.svg'
import TrashIcon from '../../Assets/Images/trash.svg'
import AddIcon from '../../Assets/Images/plus-circle.svg'

const Sections = (props) => {
    const project = props.project;
    const [section, setSection] = useState(props.section);
    const canEditSections = props.canEditSections;
    const editingProject = props.editingProject;
    const setIsLoading = props.setIsLoading;
    const setIsLoadingProjectSections = props.setIsLoadingProjectSections;
    const [reloadChapter, setReloadChapter] = useState(false);
    const [reloadSection, setReloadSection] = useState(false);
    const [enableSectionHandler, setEnableSectionHandler] = useState(false);
    const [showSectionModal, setShowSectionModal] = useState(false);
    const [showChapterModal, setShowChapterModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [isEditingSection, setIsEditingSection] = useState(false);

    const sectionButtonText = isEditingSection ? "Stop Editing" : "Edit Section";

    if (enableSectionHandler && !editingProject) {
        section.Enabled = !section.Enabled;
        setIsLoadingProjectSections(true);
        ApiManager.getInstance().deleteSection(section).then(() => {
            setReloadSection(true);
            setEnableSectionHandler(false);
            setIsLoadingProjectSections(false);
            setIsLoading(true);
        });
    }
    else if (enableSectionHandler && editingProject) {
        section.Enabled = !section.Enabled;
        setIsLoadingProjectSections(true)
        ApiManager.getInstance().deleteProjectSection(section).then(() => {
            setIsLoading(true);
            setEnableSectionHandler(false);
            setIsLoadingProjectSections(false);
        });
    }

    if (reloadSection && !editingProject) {
        ApiManager.getInstance().getSectionById(section).then((result) => {
            setSection(result);
            setReloadChapter(true);
        });
        setReloadSection(false);
    }
    else if (reloadSection && editingProject) {
        ApiManager.getInstance().getProjectSectionById(section).then((result) => {
            setSection(result);
            setReloadChapter(true);
        });
        setReloadSection(false);
    }

    function CustomHeader({ children, eventKey }) {
        const customHeaderClicked = useAccordionButton(eventKey);
        return (
            <>
                <span style={{ cursor: "pointer" }} as={Button} onClick={customHeaderClicked}>{children}</span>
                {
                    isEditingSection &&
                    <div style={{ marginLeft: "auto" }}>
                        {!editingProject && <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-sections-add"}>Add Chapter to Section</Tooltip>}>
                            <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => setShowChapterModal(true)}><img src={AddIcon} alt="Add Icon" /></Button>
                        </OverlayTrigger>}
                        <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-sections-edit"}>Edit Section</Tooltip>}>
                            <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => setShowSectionModal(true)}><img src={PencilIcon} alt="Pencil Icon" /></Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-sections-remove"}>Remove Section</Tooltip>}>
                            <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => setShowConfirmationModal(true)}><img src={TrashIcon} alt="Trash Icon" /></Button>
                        </OverlayTrigger>
                    </div>
                }
            </>
        );
    }

    return (
        <>
            <SectionModal
                editingProject={editingProject}
                section={section}
                setIsLoading={setIsLoading}
                showSectionModal={showSectionModal}
                setShowSectionModal={setShowSectionModal}
                createOrUpdate={2} />
            <ChapterModal
                editingProject={editingProject}
                section={section}
                showChapterModal={showChapterModal}
                setShowChapterModal={setShowChapterModal}
                setReloadSection={setReloadSection}
                createOrUpdate={1} />
            <ConfirmationModal
                type="section"
                setYesHandler={setEnableSectionHandler}
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                Name={section.Name} />
            {section.Enabled &&
                <Card>
                    <Card.Header style={{ display: "flex" }}>
                        <CustomHeader section={section} eventKey={section.Id}>{section.SortOrder} | {section.Name}</CustomHeader>
                    </Card.Header>
                    <Accordion.Collapse eventKey={section.Id}>
                        <Card.Body>
                            {section.Chapters.filter(chapt => chapt.Enabled) < 1 ? <p>No Chapters Assigned</p> :
                                section.Chapters.sort((a, b) => a.SortOrder - b.SortOrder).map((chapter) =>
                                    <Accordion key={chapter.Id}>
                                        <Chapters
                                            chapter={chapter}
                                            setReloadChapter={setReloadChapter}
                                            setReloadSection={setReloadSection}
                                            isEditingSection={isEditingSection}
                                            reloadChapter={reloadChapter}
                                            editingProject={editingProject}
                                            section={section}
                                            canEditSections={canEditSections}
                                            project={project}
                                        />
                                    </Accordion>
                                )}
                            {canEditSections &&
                                <>
                                    <div style={{ display: "flex", marginTop: "10px" }}>
                                        <Button
                                            style={{ marginRight: 15 }}
                                            size="sm"
                                            variant="outline-secondary"
                                            onClick={() => setIsEditingSection(!isEditingSection)}>{sectionButtonText}
                                        </Button>
                                    </div>
                                </>}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>
            }
        </>

    );
}

export default Sections;