import { React, useState } from 'react'
import { Button, Modal, Form, Row, Col } from "react-bootstrap";
import { ApiManager } from "../../ApiManager"
import { ThreeDots } from 'react-loader-spinner';

const AddSectionsToProjectModal = (props) => {

    const projectId = props.projectId;
    const projectName = props.projectName;
    const [sections, setSections] = useState({});
    const [sectionsToAdd, setsectionsToAdd] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isReloading, setIsReloading] = useState(true);

    if (isLoading) {
        ApiManager.getInstance().getAllSections().then((result) => {
            result != null || undefined ? setSections(result) : setSections([]);
            setIsLoading(false);
            setIsReloading(false);
        });
    }

    const handleClose = () => {
        setsectionsToAdd([]);
        props.setShowAddSectionsToProjectModal(false);
    }

    const renderCheckboxes = () => {
        const rows = [];

        for (let i = 0; i < sections.length; i += 3) {
            rows.push(
                <Row key={i} >
                    {sections.filter(sec => sec.Enabled).slice(i, i + 3).map(section => (
                        <Col key={section.Id} md={4}>
                            <Form.Check
                                type="checkbox"
                                id={section.Id}
                                onChange={() => handleCheckboxChange(section.Id)}
                                label={section.Name}
                                style={{ marginBottom: '10px', marginTop: '25px', paddingLeft: '20px', paddingRight: '10px' }}
                            />
                        </Col>
                    ))}
                </Row>
            );
        }

        rows.push(
            <Row key="allSectionsRow">
                <Col key="allSectionsCol" md={4}>
                    <Form.Check
                        type="checkbox"
                        id={`allsections-${projectId}`}
                        onChange={() => handleAllSectionsCheckboxChange()}
                        label='All Sections'
                        style={{ marginBottom: '10px', marginTop: '25px', paddingLeft: '20px', paddingRight: '10px' }}
                    />
                </Col>
            </Row>
        )

        return rows;
    };

    const handleCheckboxChange = (id) => {
        if (sectionsToAdd.includes(id)) {
            const temp = sectionsToAdd.filter(section => section !== id)
            setsectionsToAdd(temp);
        }
        else {
            setsectionsToAdd([...sectionsToAdd, id])
        }
    };

    const handleAllSectionsCheckboxChange = () => {
        if (sectionsToAdd.length > 0)
            setsectionsToAdd([]);
        else
            setsectionsToAdd(sections.filter(sec => sec.Enabled).map(sec=>sec.Id));
    }

    const handleSave = () => {
        console.log(sectionsToAdd);
        setIsReloading(true);
        ApiManager.getInstance().updateProjectAddSection(sectionsToAdd, projectId).then((res) => {
            props.setShowAddSectionsToProjectModal(false);            
            props.setIsLoadingProject(true);
            setsectionsToAdd([]);
            setIsReloading(false);
        });
    }

    return (
        <>
            <Modal show={props.showAddSectionsToProjectModal} onHide={handleClose} size="lg" centered>
                <Modal.Header closeButton>
                    <Modal.Title>Add Sections to: <b>{projectName}</b></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="row g-3">
                        {
                            !isLoading && !isReloading ? renderCheckboxes() : <div style={{ justifyContent: "center", display: "flex", margin: "0 auto" }}> <ThreeDots color="#007396" /> </div>
                        }
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}> Close </Button>
                    <Button variant="outline-primary" onClick={handleSave}> Save </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default AddSectionsToProjectModal;