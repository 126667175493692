import { React, useState } from 'react'
import { Button, Modal, Form } from "react-bootstrap";
import { ApiManager } from "../../ApiManager"

const StrategyModal = (props) => {
    const [strategyDescription, setStrategyDescription] = useState(props.strategy !== undefined ? props.strategy.Description : "");

    const handleSaveStrategy = () => {
        var strategyToAdd = {
            Description: strategyDescription,
            ChapterId: props.chapter.Id,
            Enabled: true
        };
        if (!props.editingProject) {
            if (props.createOrUpdate === 1) {
                ApiManager.getInstance().createStrategy(strategyToAdd).then(() => {
                    props.setReloadChapter(true);
                    props.setShowStrategyModal(false);
                });
            }
            else {
                strategyToAdd.Id = props.strategy.Id;
                ApiManager.getInstance().updateStrategy(strategyToAdd).then(() => {
                    props.setReloadChapter(true);
                    props.setShowStrategyModal(false);
                });
            }
        }
        else {

            strategyToAdd.Id = props.strategy.Id;
            strategyToAdd.Priority = props.strategy.Priority;
            strategyToAdd.Recommendations = props.strategy.Recommendations;
            strategyToAdd.Observations = props.strategy.Observations;
            strategyToAdd.Complies = props.strategy.Complies;

            ApiManager.getInstance().updateProjectStrategy(strategyToAdd).then(() => {
                props.setReloadChapter(true);
                props.setShowStrategyModal(false);
            });
        }
        props.setShowStrategyModal(false);
    };

    return (
        <Modal show={props.showStrategyModal} onHide={() => props.setShowStrategyModal(false)}>
            <Modal.Header closeButton>
                <Modal.Title>{props.createOrUpdate === 1 ? "Add Strategy to: " + props.chapter.Name : "Edit Strategy: " + props.strategy.Description}  </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="row g-3">
                    <Form.Group>
                        <Form.Label>Strategy Description</Form.Label>
                        <Form.Control onChange={(e) => setStrategyDescription(e.target.value)} as="textarea" autoFocus rows={2} defaultValue={props.strategy !== undefined ? props.strategy.Description : ""} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={() => props.setShowStrategyModal(false)}> Close </Button>
                <Button variant="outline-primary" onClick={handleSaveStrategy}> Save </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default StrategyModal;