import 'bootstrap/dist/css/bootstrap.css';
import React from 'react';
import { createRoot } from 'react-dom/client';
import App from './App';
import { PublicClientApplication, EventType } from "@azure/msal-browser";
import { msalConfig, loginApiRequest } from "./authconfig";
import { BrowserRouter as Router } from 'react-router-dom';
import axios from "axios";
import './index.css'

export const msalInstance = new PublicClientApplication(msalConfig);

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href');
const rootElement = document.getElementById('root');
const root = createRoot(rootElement);

msalInstance.addEventCallback((message) => {
    if (message.eventType === EventType.LOGIN_SUCCESS) 
        SetLocalStorageAuth();           
    if (message.eventType === EventType.LOGOUT_SUCCESS)
        localStorage.removeItem("UniversalDesignAuth");
});

axios.interceptors.request.use(
    async (config) => {
        const account = msalInstance.getAllAccounts()[0];
        const msalResponse = await msalInstance.acquireTokenSilent({
            ...loginApiRequest,
            account: account,
        });
        config.headers['Authorization'] = `Bearer ${msalResponse.accessToken}`;
        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);


async function SetLocalStorageAuth() {
    const account = msalInstance.getAllAccounts()[0];
    const msalResponse = await msalInstance.acquireTokenSilent({
        ...loginApiRequest,
        account: account,
    });
    localStorage.setItem("UniversalDesignAuth", msalResponse.accessToken);
}

root.render(
        <Router basename={baseUrl}>
            <App instance={msalInstance} />
        </Router>
);
