import { React } from 'react'
import { Button, Modal } from "react-bootstrap";
const ConfirmationModal = (props) => {

    const handleYes = () => {
        props.setYesHandler(true);
        props.setShowConfirmationModal(false);
    };

    const handleClose = () => {
        props.setShowConfirmationModal(false);
    };

    return (
        <Modal show={props.showConfirmationModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Warning</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                Are you sure you want to delete {props.type}: {props.Name}
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-danger" onClick={handleYes}>Yes</Button>
                <Button variant="outline-secondary" onClick={handleClose}>No</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ConfirmationModal;