import { React, useState } from 'react'
import { Button, Modal, Form } from "react-bootstrap";
import { ApiManager } from "../../ApiManager"

const RecommendationModal = (props) => {

    const handleClose = () => props.setShowRecommendationModal(false);
    const recommendation = props.recommendation;
    const [recommendationValue, setRecommendationValue] = useState(recommendation.Value);

    const handleSaveRecommendation = () => {
        var recommendationToAdd = {
            Value: recommendationValue,
            Enabled: true,
            Id: recommendation.Id
        };
        ApiManager.getInstance().updateRecommendation(recommendationToAdd).then(() => {
            props.setShowRecommendationModal(false);
            props.getAllRecommendations();
        });       
    };

    return (
        <Modal show={props.showRecommendationModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Edit Recommendation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="row g-3">
                    <Form.Group>
                        <Form.Label>Recommendation</Form.Label>
                        <Form.Control onChange={(e) => setRecommendationValue(e.target.value)} as="textarea" autoFocus rows={1} defaultValue={props.recommendation !== undefined ? props.recommendation.Value : ""} />
                    </Form.Group>
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}> Close </Button>
                <Button variant="outline-primary" onClick={handleSaveRecommendation}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default RecommendationModal;