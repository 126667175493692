import { Accordion, Button, useAccordionButton, Card, OverlayTrigger, Tooltip, ListGroup } from 'react-bootstrap';
import { React, useState } from 'react';
import { ApiManager } from '../../ApiManager';
import Strategies from './Strategies';
import StrategyModal from "../Modals/StrategyModal";
import ConfirmationModal from '../Modals/ConfirmationModal';
import ChapterModal from '../Modals/ChapterModal';
import PencilIcon from '../../Assets/Images/pencil-square.svg'
import TrashIcon from '../../Assets/Images/trash.svg'
import AddIcon from '../../Assets/Images/plus-circle.svg'

const Chapters = (props) => {
    const project = props.project;
    const [chapter, setChapter] = useState(props.chapter);
    const setReloadChapter = props.setReloadChapter;
    const setReloadSection = props.setReloadSection;
    const isEditingSection = props.isEditingSection;
    const reloadChapter = props.reloadChapter;
    const editingProject = props.editingProject;
    const section = props.section;

    const [enableChapterHandler, setEnableChapterHandler] = useState(false);
    const [showChapterModal, setShowChapterModal] = useState(false);
    const [showStrategyModal, setShowStrategyModal] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);

    function CustomHeader({ children, eventKey }) {
        const customHeaderClicked = useAccordionButton(eventKey);

        return (
            <>
                <span style={{ cursor: "pointer", textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", width: "50%" }} as={Button} onClick={customHeaderClicked}>{children}</span>
                {
                    isEditingSection &&
                    <div style={{ marginLeft: "auto" }}>
                        {!editingProject && <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-chapters-add"}>Add Strategy to Chapter</Tooltip>}>
                            <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => setShowStrategyModal(true)}><img src={AddIcon} alt="Add Icon" /></Button>
                        </OverlayTrigger>}
                        <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-chapters-edit"}>Edit Chapter</Tooltip>}>
                            <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => setShowChapterModal(true)}><img src={PencilIcon} alt="Pencil Icon" /></Button>
                        </OverlayTrigger>
                        <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-sections-remove"}>Remove Chapter</Tooltip>}>
                            <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => setShowConfirmationModal(true)}><img src={TrashIcon} alt="Trash Icon" /></Button>
                        </OverlayTrigger>
                    </div>
                }
            </>
        );
    }

    if (reloadChapter && !editingProject) {
        ApiManager.getInstance().getChapterById(chapter).then((result) => {
            setChapter(result);
            setReloadChapter(false);
        });
    }
    else if (reloadChapter && editingProject) {
        ApiManager.getInstance().getProjectChapterById(chapter).then((result) => {
            setChapter(result);
            setReloadChapter(false);
        });
    }

    if (enableChapterHandler && !editingProject) {
        chapter.Enabled = !chapter.Enabled;
        ApiManager.getInstance().deleteChapter(chapter).then((result) => {
            setReloadSection(true);
            setEnableChapterHandler(false);
        });
    }
    else if (enableChapterHandler && editingProject) {
        chapter.Enabled = !chapter.Enabled;
        ApiManager.getInstance().deleteProjectChapter(chapter).then((result) => {
            setReloadSection(true);
            setEnableChapterHandler(false);
        });
    }

    return (
        <>
            <ChapterModal
                chapter={chapter}
                editingProject={editingProject}
                createOrUpdate={2}
                section={section}
                showChapterModal={showChapterModal}
                setShowChapterModal={setShowChapterModal}
                setReloadSection={setReloadSection} />
            <StrategyModal
                editingProject={editingProject}
                createOrUpdate={1}
                chapter={chapter}
                showStrategyModal={showStrategyModal}
                setShowStrategyModal={setShowStrategyModal}
                setReloadChapter={setReloadChapter} />
            <ConfirmationModal
                type="Chapter"
                setYesHandler={setEnableChapterHandler}
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                Name={chapter.Name} />
            {chapter.Enabled &&
                <Card key={chapter.Id}>
                    <Card.Header style={{ display: "flex" }}>
                        <CustomHeader chapter={chapter} eventKey={chapter.Id}>{chapter.SectionSortOrder}.{chapter.SortOrder} {chapter.Name}</CustomHeader>
                    </Card.Header>
                    <Accordion.Collapse eventKey={chapter.Id}>
                        <Card.Body>
                            {chapter.Strategies.filter(strat => strat.Enabled) < 1 ? <p>No Strategies Assigned</p> :
                                chapter.Strategies.map((strat) =>
                                    <ListGroup key={strat.Id}>
                                        <Strategies
                                            key={strat.Id}
                                            strategy={strat}
                                            chapter={chapter}
                                            editingProject={editingProject}
                                            IsEditingSection={isEditingSection}
                                            setReloadChapter={setReloadChapter}
                                            project={project}
                                        />
                                    </ListGroup>
                                )}
                        </Card.Body>
                    </Accordion.Collapse>
                </Card>}
        </>
    );
};

export default Chapters;