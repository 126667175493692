import { ListGroup, Badge, Button, Pagination } from 'react-bootstrap';
import { React, useState } from 'react';
import { useNavigate } from "react-router-dom";
import AddProjectModal from '../Modals/AddProjectModal';

const ProjectList = (props) => {

    const projects = props.projects;
    var totalPages = props.totalPages;
    var setPageNumber = props.setPageNumber;
    var pageNumber = props.pageNumber;
    var setIsLoading = props.setIsLoading;

    const [active, setActive] = useState(pageNumber)

    const navigate = useNavigate();
    const [showAddProjectModal, setShowAddProjectModal] = useState(false);
    let paginationItems = [];

    for (let i = 1; i <= totalPages; i++) {
        paginationItems.push(<Pagination.Item key={i} active={i === active}>{i}</Pagination.Item>,);
    }

    function projectOnClick(project) {
        navigate(`/projects/${project.Id}`);
    };

    const pageClicked = (e) => {
        if (e.target.text !== undefined) {
            var pageNumber = Number(e.target.text);
            setActive(pageNumber);
            setPageNumber(pageNumber);
            setIsLoading(true);
        }
    }

    return (
        <>
            <h1>Projects</h1>
            <div className={"mb-3 text-end"}>
                <Button size="sm" variant="outline-success" onClick={() => setShowAddProjectModal(true)}>Add new Project</Button>
            </div>
            <AddProjectModal
                setIsLoading={props.setIsLoading}
                showAddProjectModal={showAddProjectModal}
                setShowAddProjectModal={setShowAddProjectModal}
                setProjectToAdd={props.setProjectToAdd}
                setSaveProject={props.setSaveProject} />
            {projects.length < 1 ? <h4>No Projects created</h4> :
                <>
                    <h3 className="mb-4">In-Progress</h3>
                    <ListGroup as="ul" className="mb-4">
                        {projects.map((project) =>
                            project.Enabled &&
                            <ListGroup.Item key={project.Id} className="d-flex justify-content-between align-items-start" action onClick={() => projectOnClick(project)} >
                                <div className="ms-2 me-auto">
                                    <div className="fw-bold"> {project.Name}</div>
                                    Created for {project.ClientName}
                                </div>
                                <Badge bg="transparent" text="dark"> Created {project.Created}</Badge>
                            </ListGroup.Item>
                        )}
                    </ListGroup>
                    <div style={{ display: "flex", justifyContent: "center", alignItems: "center" }}>
                        <Pagination onClick={(e) => pageClicked(e)}>{paginationItems}</Pagination>
                    </div>
                </>
            }
        </>
    );
}

export default ProjectList;