import { React, useEffect, useState } from 'react';
import { Button, Form, Col, Row, ListGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
import { ApiManager } from '../../ApiManager';
import PencilIcon from '../../Assets/Images/pencil-square.svg'
import TrashIcon from '../../Assets/Images/trash.svg'
import ConfirmationModal from '../Modals/ConfirmationModal';
import RecommendationModal from '../Modals/RecommendationModal';

export const RecommendationForm = () => {

    const [enableRecommendationHandler, setEnableRecommendationHandler] = useState(false);
    const [showConfirmationModal, setShowConfirmationModal] = useState(false);
    const [recommendationValue, setRecommendationValue] = useState("");
    const [allRecommendations, setAllRecommendations] = useState([]);
    const [recommendationToRemove, setRecommendationToRemove] = useState({});
    const [recommendationToEdit, setRecommendationToEdit] = useState({});
    const [showRecommendationModal, setShowRecommendationModal] = useState(false);

    const onRecommendationFormSubmit = (e) => { e.preventDefault(); };

    useEffect(() => {
        getAllRecommendations();
    }, [])

    const getAllRecommendations = () => {
        ApiManager.getInstance().getAllRecommendations().then((result) => {
            setAllRecommendations(result.data);
        });
    }

    const addRecommendation = () => {
        ApiManager.getInstance().createRecommendation(recommendationValue).then((result) => {
            setRecommendationValue("");
            getAllRecommendations();
        });
    }

    const removeRecommendationHandler = (recommendation) => {
        setRecommendationToRemove(recommendation);
        setShowConfirmationModal(true);
    }

    const editRecommendationHandler = (recommendation) => {
        setRecommendationToEdit(recommendation);
        setShowRecommendationModal(true);
    }

    if (enableRecommendationHandler) {
        ApiManager.getInstance().deleteRecommendation(recommendationToRemove).then(() => {
            setEnableRecommendationHandler(false);
            getAllRecommendations();
        });
    }

    return (
        <>
            <RecommendationModal
                recommendation={recommendationToEdit}
                showRecommendationModal={showRecommendationModal}
                setShowRecommendationModal={setShowRecommendationModal}
                getAllRecommendations={getAllRecommendations}
            />
            <ConfirmationModal
                type="Recommendation"
                setYesHandler={setEnableRecommendationHandler}
                showConfirmationModal={showConfirmationModal}
                setShowConfirmationModal={setShowConfirmationModal}
                Name={recommendationToRemove.Value} />
            <Form onSubmit={(e) => onRecommendationFormSubmit(e)}>
                <Row className="mb-3">
                    <Form.Group as={Col} className="g-3 mt-3">
                        <Form.Label>Add Recommendation</Form.Label>
                        <Form.Control onChange={(e) => setRecommendationValue(e.target.value)} value={recommendationValue !== null ? recommendationValue : ""} type="text"></Form.Control>
                        <Button className="mt-3" variant="outline-success" onClick={() => addRecommendation()}>Add</Button>
                    </Form.Group>
                    <Form.Group as={Col} className="g-3 mt-3">
                        {allRecommendations.filter(rec => rec.Enabled).length < 1 ? <Form.Label>No Recommendations Created</Form.Label> :
                            <>
                                <Form.Label>Edit Recommendations</Form.Label>
                                <ListGroup as="ul" className="mb-4">
                                    {allRecommendations.map((recommendation) =>
                                        recommendation.Enabled &&
                                        <ListGroup.Item key={recommendation.Id} className="d-flex justify-content-between align-items-start">
                                            <div className="ms-2 me-auto"><div className="fw-bold">{recommendation.Value}</div></div>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-Recommendation-Edit"}>Edit Recommendation</Tooltip>}>
                                                    <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => editRecommendationHandler(recommendation)}><img src={PencilIcon} alt="Pencil Icon" /></Button>
                                            </OverlayTrigger>
                                            <OverlayTrigger placement="top" overlay={<Tooltip id={"tooltip-Recommendation-Removal"}>Remove Recommendation</Tooltip>}>
                                                <Button style={{ padding: "0", border: "none", background: "none", paddingRight: "5px" }} onClick={() => removeRecommendationHandler(recommendation)}><img src={TrashIcon} alt="Trash Icon" /></Button>
                                            </OverlayTrigger>
                                        </ListGroup.Item>
                                    )}
                                </ListGroup>
                            </>
                        }
                    </Form.Group>
                </Row>
            </Form >
        </>
    )

};