import { React, useState } from 'react'
import { Button, Modal, Form } from "react-bootstrap";
import { ApiManager } from "../../ApiManager"

const ChapterModal = (props) => {

    const handleClose = () => props.setShowChapterModal(false);
    const [chapterName, setChapterName] = useState(props.chapter !== undefined ? props.chapter.Name : "");
    const [chapterDescription, setChapterDescription] = useState(props.chapter !== undefined ? props.chapter.Description : "");
    const [sortOrder, setsortOrder] = useState(props.chapter !== undefined ? props.chapter.SortOrder : "");


    const handleSaveChapter = () => {
        var chapterToAdd = {
            Name: chapterName,
            Description: chapterDescription,
            SectionId: props.section.Id,
            SortOrder: sortOrder,
            Enabled: true
        };
        if (!props.editingProject) {
            if (props.createOrUpdate === 1) {
                ApiManager.getInstance().createChapter(chapterToAdd).then(() => {
                    props.setReloadSection(true);
                    props.setShowChapterModal(false);
                });
            }
            else {
                chapterToAdd.chapterId = props.chapter.Id
                ApiManager.getInstance().updateChapter(chapterToAdd).then(() => {
                    props.setReloadSection(true);
                    props.setShowChapterModal(false);
                });
            }
        }
        else {
            chapterToAdd.chapterId = props.chapter.Id
            ApiManager.getInstance().updateProjectChapter(chapterToAdd).then(() => {
                props.setReloadSection(true);
                props.setShowChapterModal(false);
            });
        }
        props.setShowChapterModal(false);
    };

    return (
        <Modal show={props.showChapterModal} onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>{props.createOrUpdate === 1 ? "Add Chapter to " + props.section.Name : "Edit Chapter: " + props.chapter.Name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form className="row g-3">
                    <Form.Group>
                        <Form.Label>Chapter Name</Form.Label>
                        <Form.Control onChange={(e) => setChapterName(e.target.value)} as="textarea" autoFocus rows={1} defaultValue={props.chapter !== undefined ? props.chapter.Name : ""} />
                    </Form.Group>
                    <Form.Group>
                        <Form.Label>Description</Form.Label>
                        <Form.Control onChange={(e) => setChapterDescription(e.target.value)} as="textarea" rows={2} defaultValue={props.chapter !== undefined ? props.chapter.Description : ""} />
                    </Form.Group>
                    {props.createOrUpdate !== 1 ? <Form.Group>
                        <Form.Label>Order</Form.Label>
                        <Form.Control onChange={(e) => setsortOrder(e.target.value)} as="textarea" autoFocus rows={1} defaultValue={props.chapter !== undefined ? props.chapter.SortOrder : ""} />
                    </Form.Group> : "" }
                </Form>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="outline-secondary" onClick={handleClose}> Close </Button>
                <Button variant="outline-primary" onClick={handleSaveChapter}>Save</Button>
            </Modal.Footer>
        </Modal>
    );
};

export default ChapterModal;