import { React, useState } from 'react'
import { Button, Modal, Form } from "react-bootstrap";
import { ApiManager } from "../../ApiManager"

const SectionModal = (props) => {

    const handleClose = () => props.setShowSectionModal(false);
    const [sectionName, setSectionName] = useState(props.section !== undefined ? props.section.Name : "");
    const [sortOrder, setsortOrder] = useState(props.section !== undefined ? props.section.SortOrder : "");
    const createOrUpdate = props.createOrUpdate;

    const handleSectionEvent = () => {
        var sectionToAdd = {
            Name: sectionName,
            SortOrder: sortOrder,
            Enabled: true
        };
        if (!props.editingProject) {
            if (createOrUpdate === 1) {
                ApiManager.getInstance().createSection(sectionToAdd).then(() => {
                    props.setIsLoading(true);
                    props.setShowSectionModal(false);
                });
            }
            else {
                sectionToAdd.Id = props.section.Id;
                ApiManager.getInstance().updateSection(sectionToAdd).then(() => {
                    props.setIsLoading(true);
                    props.setShowSectionModal(false);
                });
            }
        }
        else {
            sectionToAdd.Id = props.section.Id;
            ApiManager.getInstance().updateProjectSection(sectionToAdd).then(() => {
                props.setIsLoading(true);
                props.setShowSectionModal(false);
            });            
        }
    };

    return (
        <>
            <Modal show={props.showSectionModal} onHide={handleClose}>
                <Modal.Header closeButton>
                    <Modal.Title>{createOrUpdate === 1 ? "Add" : "Edit"} Section</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form className="row g-3">
                        <Form.Group>
                            <Form.Label>Section Name</Form.Label>
                            <Form.Control onChange={(e) => setSectionName(e.target.value)} as="textarea" autoFocus rows={1} defaultValue={props.section !== undefined ? props.section.Name : "" } />
                        </Form.Group>
                        {createOrUpdate !== 1 ? <Form.Group>
                            <Form.Label>Order</Form.Label>
                            <Form.Control onChange={(e) => setsortOrder(e.target.value)} as="textarea" autoFocus rows={1} defaultValue={props.section !== undefined ? props.section.SortOrder : ""} />
                        </Form.Group> : ""}
                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="outline-secondary" onClick={handleClose}> Close </Button>
                    <Button variant="outline-primary" onClick={handleSectionEvent}> Save </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default SectionModal;