import axios from "axios";
import { Component } from "react";

export class ApiManager extends Component {
    static myInstance = null;

    static getInstance() {
        if(this.myInstance == null)
            this.myInstance = new ApiManager();
        return this.myInstance;
    }

    async getAllSections() {
        try {
            const result = await axios.get("api/section");
            return await result.data;
        } catch (error) {
            console.log(error);
        }
    }

    async getSectionById(section) {
        try {
            const result = await axios.get(`api/section/${section.Id}`);
            return await result.data;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteSection(section) {
        try {
            const result = await axios.delete(`api/section/${section.Id}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async createSection(sectionToAdd) {
        try {
            const result = await axios.post("api/section", {
                Name: sectionToAdd.Name,
                Enabled: sectionToAdd.Enabled
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async updateSection(sectionToUpdate) {
        try {
            const result = await axios.put("api/section", {
                Enabled: sectionToUpdate.Enabled,
                Id: sectionToUpdate.Id,
                Name: sectionToUpdate.Name,
                SortOrder: sectionToUpdate.SortOrder
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async getChapterById(chapter) {
        try {
            const result = await axios.get(`api/chapter/${chapter.Id}`);
            return await result.data;
        } catch (error) {
            console.log(error);
        }
    }

    async createChapter(chapterToAdd) {
        try {
            const result = await axios.post("api/chapter", {
                Name: chapterToAdd.Name,
                Description: chapterToAdd.Description,
                SectionId: chapterToAdd.SectionId,
                Enabled: chapterToAdd.Enabled
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteChapter(chapter) {
        try {
            const result = await axios.delete(`api/chapter/${chapter.Id}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async updateChapter(chapterToUpdate) {
        try {
            const result = await axios.put("api/chapter", {
                Name: chapterToUpdate.Name,
                Description: chapterToUpdate.Description,
                SectionId: chapterToUpdate.SectionId,
                Id: chapterToUpdate.chapterId,
                Enabled: chapterToUpdate.Enabled,
                SortOrder: chapterToUpdate.SortOrder
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async createStrategy(strategyToAdd) {
        try {
            const result = await axios.post("api/strategy", {
                Description: strategyToAdd.Description,
                ChapterId: strategyToAdd.ChapterId,
                Enabled: strategyToAdd.Enabled
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteStrategy(strategy) {
        try {
            const result = await axios.delete(`api/strategy/${strategy.Id}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async updateStrategy(strategyToUpdate) {
        try {
            const result = await axios.put("api/strategy", {
                Description: strategyToUpdate.Description,
                ChapterId: strategyToUpdate.ChapterId,
                Enabled: strategyToUpdate.Enabled,
                Id: strategyToUpdate.Id,
                Priority: strategyToUpdate.Priority
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async getAllProjects(pageNumber, pageSize) {
        try {
            const result = await axios.get(`api/project/?pageNumber=${pageNumber}&pageSize=${pageSize}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async createProject(projectToAdd) {
        try {
            const result = await axios.post("api/project", {
                Name: projectToAdd.Name,
                ClientName: projectToAdd.ClientName
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteProject(project) {
        try {
            const result = await axios.delete(`api/project/${project.Id}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async getProjectById(projectId) {
        try {
            const result = await axios.get(`api/project/${projectId}`);
            return await result.data;
        } catch (error) {
            console.log(error);
        }
    }

    async updateProject(projectToUpdate) {
        try {
            const result = await axios.put('api/project/', projectToUpdate);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async updateProjectAddSection(sectionIds, projectToUpdate) {
        try {
            const result = await axios.put(`api/project/addsectiontoproject/${projectToUpdate}`, sectionIds);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async applyDefaultNumbering() {
        try {
            const result = await axios.patch(`api/section/applydefaultnumbering`);
            return await result;
        }
        catch (error) {
            console.log(error);
        }
    }

    async applyDefaultNumberingProject(projectId) {
        try {
            const result = await axios.patch(`api/project/applydefaultnumbering/${projectId}`);
            return await result;
        }
        catch (error) {
            console.log(error);
        }
    }

    async getProjectSectionById(projectSection) {
        try {
            const result = await axios.get(`api/projectsection/${projectSection.Id}`);
            return await result.data;
        } catch (error) {
            console.log(error);
        }
    }

    async updateProjectSection(projectSectionToUpdate) {
        try {
            const result = await axios.put("api/projectsection", {
                Enabled: projectSectionToUpdate.Enabled,
                Id: projectSectionToUpdate.Id,
                Name: projectSectionToUpdate.Name,
                SortOrder: projectSectionToUpdate.SortOrder
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteProjectSection(projectSection) {
        try {
            const result = await axios.delete(`api/projectsection/${projectSection.Id}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteProjectChapter(projectChapter) {
        try {
            const result = await axios.delete(`api/projectchapter/${projectChapter.Id}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async updateProjectChapter(projectChapterToUpdate) {
        try {
            const result = await axios.put("api/projectchapter", {
                Name: projectChapterToUpdate.Name,
                Description: projectChapterToUpdate.Description,
                Id: projectChapterToUpdate.chapterId,
                Enabled: projectChapterToUpdate.Enabled,
                SortOrder: projectChapterToUpdate.SortOrder
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteProjectStrategy(projectStrategy) {
        try {
            const result = await axios.delete(`api/projectstrategy/${projectStrategy.Id}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async updateProjectStrategy(projectStrategyToUpdate) {
        try {
            const result = await axios.put("api/projectstrategy", {
                Description: projectStrategyToUpdate.Description,
                Observations: projectStrategyToUpdate.Observations,
                Recommendations: projectStrategyToUpdate.Recommendations,
                Priority: projectStrategyToUpdate.Priority,
                Enabled: projectStrategyToUpdate.Enabled,
                ChapterId: projectStrategyToUpdate.ChapterId,                
                Id: projectStrategyToUpdate.Id,
                Complies: projectStrategyToUpdate.Complies,
                LastModified: new Date().toLocaleDateString('en-us', { year: '2-digit', month: '2-digit', day: '2-digit' })
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async getProjectStrategyById(projectStrategyId) {
        try {
            const result = await axios.get(`api/projectstrategy/${projectStrategyId}`);
            return await result.data;
        } catch (error) {
            console.log(error);
        }
    }

    async getProjectChapterById(projectChapter) {
        try {
            const result = await axios.get(`api/projectchapter/${projectChapter.Id}`);
            return await result.data;
        } catch (error) {
            console.log(error);
        }
    }

    async createProjectStrategyPhotos(projectStrategyPhotoToCreate) {
        try {
            const result = await axios({
                method: 'post',
                url: 'api/projectstrategyphoto/',
                data: projectStrategyPhotoToCreate,
                headers: { 'Content-Type': 'multipart/form-data' }
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async getProjectStrategyPhotosByStrategyId(projectStrategyId) {
        try {
            const result = await axios.get(`api/projectstrategyphoto/${projectStrategyId}`);
            return await result.data;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteProjectStrategyPhoto(projectStrategyId) {
        try {
            const result = await axios.delete(`api/projectstrategyphoto/${projectStrategyId}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async updateProjectStrategyPhoto(projectStrategyPhoto) {
        try {
            const result = await axios.put("api/projectstrategyphoto", {
                Id: projectStrategyPhoto.Id,
                Path: projectStrategyPhoto.Path,
                ProjectStrategyId: projectStrategyPhoto.ProjectStrategyId,
                Rotated: projectStrategyPhoto.Rotated
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async getAllRecommendations() {
        try {
            const result = await axios.get(`api/recommendation`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async createRecommendation(recommendationToAdd) {
        try {
            const result = await axios.post("api/recommendation", {
                Value: recommendationToAdd
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async deleteRecommendation(recommendation) {
        try {
            const result = await axios.delete(`api/recommendation/${recommendation.Id}`);
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async updateRecommendation(recommendationToUpdate) {
        try {
            const result = await axios.put("api/recommendation", {
                Value: recommendationToUpdate.Value,
                Enabled: recommendationToUpdate.Enabled,
                Id: recommendationToUpdate.Id
            });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

    async getPdfById(projectId) {
        try {
            const result = await axios.get(`api/pdf/${projectId}`, { responseType: 'blob' });
            return await result;
        } catch (error) {
            console.log(error);
        }
    }

}

export default ApiManager