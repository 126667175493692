import { React, useState } from 'react'
import { Navigate, Route, Routes, useLocation } from "react-router-dom";
import { AuthenticatedTemplate, UnauthenticatedTemplate } from "@azure/msal-react";
import { Container } from 'react-bootstrap';
import { ApiManager } from "../../ApiManager";
import { ThreeDots } from 'react-loader-spinner'
import ProjectList from './ProjectList';
import ProjectDetails from './ProjectDetails';
import { useEffect } from 'react';

export const Projects = () => {
    const location = useLocation();
    const [projects, setProjects] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const pageSize = 5;
    const [totalPages, setTotalPages] = useState(1);
    const [saveProject, setSaveProject] = useState(false);
    const [projectToAdd, setProjectToAdd] = useState({});

    const getAllProjects = () => {
        ApiManager.getInstance().getAllProjects(pageNumber, pageSize).then((response) => {
            var headerData = JSON.parse(response.headers.paginationdata);
            setTotalPages(headerData.TotalPages);
            var data = response.data;
            data.map((proj) => {
                var splitDate = proj.Created.split("T");
                var dateString = splitDate[0].toString();
                let [y, m, d] = dateString.split(/\D/);
                proj.Created = `${m}/${d}/${y}`;
                return proj;
            });
            setProjects(data);
            setIsLoading(false);
            setSaveProject(false);
            setProjectToAdd({});
        });
    };

    useEffect(() => {
        if (location.state !== null && location.state.isLoading === true) 
            getAllProjects();                
    }, [location]) // eslint-disable-line react-hooks/exhaustive-deps
    
    if (isLoading && !saveProject) {
        getAllProjects();
    }        

    if (isLoading && saveProject) {
        ApiManager.getInstance().createProject(projectToAdd).then(() => {
            getAllProjects();
        })
    }

    return (
        <>
            <AuthenticatedTemplate>
                <Container className="p-5">
                    {isLoading ?
                        <div style={{ justifyContent: "center", display: "flex", margin: "0 auto" }}>
                            <ThreeDots color="#007396" />
                        </div>
                        :
                        <>
                            {<>
                                <Routes>
                                    <Route path="/"
                                        element={
                                            <ProjectList
                                                projects={projects}
                                                setIsLoading={setIsLoading}
                                                totalPages={totalPages}
                                                setPageNumber={setPageNumber}
                                                pageNumber={pageNumber}
                                                setProjectToAdd={setProjectToAdd}
                                                setSaveProject={setSaveProject}
                                            />}
                                    />
                                    <Route path=":projectId" element={<ProjectDetails />} />
                                </Routes>
                            </>}
                        </>}
                </Container>
            </AuthenticatedTemplate>
            <UnauthenticatedTemplate>
                <Container>
                    <Navigate to="/" replace />;
                </Container>
            </UnauthenticatedTemplate>
        </>
    );
};
